@import "~antd/dist/antd.css";

/*reset http://meyerweb.com/eric/tools/css/reset/*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
address {
  font-style: normal;
}
img {
  max-width: 100%;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus,
button:focus,
video:focus,
input:focus,
textarea:focus {
  outline: none;
}
a,
button {
  transition: all 0.3s ease-in;
}

/* fonts */
@font-face {
  font-family: "Avenir Book";
  src: url("./assets/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("./assets/fonts/AvenirLTStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Roman";
  src: url("./assets/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("./assets/fonts/AvenirLTStd-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Medium";
  src: url("./assets/fonts/AvenirLTStd-Medium.woff2") format("woff2"),
    url("./assets/fonts/AvenirLTStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("./assets/fonts/Avenir-Heavy.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.woff2") format("woff2"),
    url("./assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*variables*/
:root {
  /*fonts*/
  --fontAvenirMedium: "Avenir Medium", sans-serif;
  --fontAvenirRoman: "Avenir Roman", sans-serif;
  --fontAvenirBook: "Avenir Book", sans-serif;
  --fontAvenirHeavy: "Avenir Heavy", sans-serif;
  --fontLatoRegular: "Lato", sans-serif;

  /*colors*/
  --lightWhite: #f6fbff;
  --white: #fff;
  --lightOrange: #fad6a6;
  --ligthBlue: #a9aebe;
  --darkBlue: #1a4365;
  --primaryBlue: #a3b8ca;
  --secondaryGrey: #2c5d85;
  --primaryRed: #e02020;
  --titleBlue: #184265;
  --mainTitle: #1d3e59;
  --defaultTextColor: #3a424b;
  --greySubtitle: #7c7c7c;
  --greyMainText: #939eab;
  --asideTxtTitle: #acbfce;
  --linkAvatar: #0e1b42;
  --titleCards: #1e1c1c;
  --subtitleCards: #7a7979;
  --greyArticle: #92a6bf;
  --lightGrey: #9ea1a4;
  --primaryGrey: #222222;

  --borderInput: #dadaed;
  --sessionCBlueColor: #107fd4;
  --playBtn: #3a678a;
  --bgPopup: #ebeef6;
  --modalTitle: #173a56;
  --cancelBtn: #9ca3ab;
  --blueExams: #204c70;
  --bgQuestion: #e9ecf4;
  --bgActiveQuestion: #27567b;
  --fontColorQuestion: #544837;
  --brightBlue: #0091ff;

  /*gradients*/
  --gradientAside: linear-gradient(190deg, #3c74a1 0%, #0e324f 100%);
  --gradientBackBtn: linear-gradient(to bottom, #f8d9af, #fbd199);
  --gradientHomePage: linear-gradient(
    198deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(60, 116, 161, 1) 0%,
    rgba(14, 50, 79, 1) 100%
  );
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  min-height: 100%;
  position: relative;
  margin-top: 0 !important;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  background: var(--lightWhite);
}

body {
  font-family: var(--fontAvenirBook);
  background: var(--lightWhite);
  line-height: 1.5;
}

.ant-input:hover {
  border-color: var(--borderInput);
  box-shadow: none;
}
.ant-input:focus,
.ant-input-focused {
  border-color: var(--borderInput);
  box-shadow: none;
}
textarea.ant-input {
  padding: 14px 20px 20px 20px !important;
}
.ant-input-affix-wrapper {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

.ant-input-affix-wrapper-focused input {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

*:focus::-moz-placeholder {
  color: transparent !important;
}

*[autofocus]::-moz-placeholder:focus {
  color: var(--ligthBlue) !important;
}

*:focus:-moz-placeholder {
  color: transparent !important;
}

*[autofocus]:-moz-placeholder:focus {
  color: var(--ligthBlue) !important;
}

*:focus::-webkit-input-placeholder {
  color: transparent !important;
}

*[autofocus]::-webkit-input-placeholder {
  color: var(--ligthBlue) !important;
}

.ant-input[disabled] {
  background: #fff !important;
}

.ant-input-affix-wrapper-disabled {
  border: 1px solid var(--borderInput) !important;
}

.ant-spin.ant-spin-spinning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.55);
  z-index: 9999;
}
.ant-spin-spinning .ant-spin-dot {
  top: 50%;
}
